import timeUnit from "enums/time-unit";
import { transactionDateStore } from "hooks";

export const dashboardFilterState = (venue) => {

  const { venueId, startDate, endDate } = transactionDateStore(venue);
  return {
    venueId,
    searchKey: "",
    status: "",
    pageable: true,
    page: 1,
    pageSize: 20,
    dateRange: [startDate, endDate],
    locationIds: [],
    interval: timeUnit.Hour,
  };
};
