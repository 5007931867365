import React, { useContext, useCallback, useMemo, useState } from "react";
import { HeaderA } from "components/headers";
import { ModuleWrapper } from "components/fragments";
import { useApi, useMount, useFilter } from "hooks";
import { Icon } from "components/commons";
import SalesReportFilter from "./sales-report-filter";
import lang from "translations";
import { VenueContext } from "contexts";
import { graphTotalSales, salesReportListRequest, barStackChartTotalSales } from "mappers";
import { measureTime, toApiDateTimeFormat } from "services/date.service";
import SalesCharts from "./sales-charts";
import { salesReportFilterState } from "./filters";
import { getGraphSalesReport, searchSalesReport, searchSalesReportExport } from "apis";
import { mapObject } from "services";
import { mixpanel, TrackEvent } from "mixpanel";

const SalesReport = () => {
  const { venue } = useContext(VenueContext);
  const [params, setParams] = useState({});
  const { modifyFilter, modifyFilters, clearFilter, filterState, requestState } = useFilter(
    salesReportFilterState(venue)
  );

  // const [hasAppliedFilter, setHasAppliedFilter] = useState(isFilterDirty);

  useMount(() => {
    fetchCharts(requestState);

    mixpanel.track(TrackEvent.VisitedPage, {
      Page: lang.salesReport,
    });
  });

  const { request: searchSalesReportExportRequest, loading: searchSalesReportExportLoading } =
    useApi({
      api: searchSalesReportExport,
    });
  const graphOfTotalSales = useApi({
    api: searchSalesReport,
    mapper: graphTotalSales,
    handleOwnError: true,
  });

  const lineChartTotalSales = useApi({
    api: getGraphSalesReport,
    mapper: barStackChartTotalSales,
    handleOwnError: true,
  });

  const fetchCharts = useCallback(
    async (filterState) => {
      const { dateRange } = filterState;
      const params = {
        ...filterState,
        startDateTime: toApiDateTimeFormat(dateRange[0]),
        endDateTime: toApiDateTimeFormat(dateRange[1], true),
        splitType: measureTime([dateRange[0], dateRange[1]]).getSplitType(),
      };
      setParams(params);
      const charts = [graphOfTotalSales, lineChartTotalSales];

      try {
        await Promise.all(charts.map((request) => request.request(params)));
      } catch (error) {
        console.error("Error occurred during API requests:", error);
      }
    },
    [graphOfTotalSales, lineChartTotalSales]
  );

  const graphs = useMemo(() => {
    const charts = {
      graphOfTotalSales,
      lineChartTotalSales,
    };
    Object.keys(charts).forEach((chart) => {
      charts[chart].retry = () => {
        fetchCharts(params);
      };
    });
    return charts;
  }, [graphOfTotalSales, lineChartTotalSales, params, fetchCharts]);

  const applyFilterCb = useCallback(
    async (searchKey) => {
      const { requestState } = modifyFilters({ page: 1, searchKey });
      await fetchCharts(requestState);

      // setHasAppliedFilter(true);
    },
    [fetchCharts, modifyFilters]
  );

  const clearFilterCb = useCallback(() => {
    const { requestState } = clearFilter();
    fetchCharts(requestState);

    // setHasAppliedFilter(false);
  }, [clearFilter, fetchCharts]);

  const exportXlsx = useCallback(() => {
    searchSalesReportExportRequest(mapObject({ ...requestState }, salesReportListRequest));
  }, [searchSalesReportExportRequest, requestState]);

  return (
    <ModuleWrapper>
      <HeaderA
        title={lang.salesReport}
        description={lang.viewTheSalesSummaryOfYourVenue}
        button={{
          iconPrefix: <Icon className="mr-sm" name="download" paddingless fontSize={12} />,
          text: lang.exportXlsx,
          loading: searchSalesReportExportLoading,
          disabled: searchSalesReportExportLoading,
          onClick: () => {
            exportXlsx();
          },
        }}
        className="mb-md"
      />
      <SalesReportFilter
        fetchCharts={fetchCharts}
        filterState={filterState}
        requestState={requestState}
        modifyFilter={modifyFilter}
        applyFilter={applyFilterCb}
        clearFilter={clearFilterCb}
      />
      <SalesCharts {...graphs} />
    </ModuleWrapper>
  );
};

export default SalesReport;
