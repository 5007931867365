import React, { useCallback, useContext, useState, useMemo } from "react";
import { ModuleWrapper } from "components/fragments";
import { HeaderA } from "components/headers";
import { Pill, Text, DataTable, ButtonLink, Icon } from "components/commons";
import { useApi, useFilter, useMount } from "hooks";
import { mapObject, pluralize } from "services";
import { Path } from "paths";
import { PillType } from "enums";
import columns from "./columns";
import lang from "translations";
import { formatNumberToMoneyWithCurrencySymbol } from "services/money.service";
import { VenueContext } from "contexts";
import MpBalanceMovementOutFilter from "./mp-balance-movement-out-filter";
import { balanceMovementListFilterState } from "./filters";
import {
  balanceMovementOutListFilterRequest,
  balanceMovementOutListResponse,
} from "mappers/multi-property.mapper";
import {
  searchBalanceMovementsOut,
  searchBalanceMovementsOutReportExport,
} from "apis/multi-property.api";
import { mixpanel, TrackEvent } from "mixpanel";
import useFilterStore, { filterName } from "hooks/filterStore";
import { isEmpty } from "lodash";

const MpBalanceMovementOutList = () => {
  const { venue } = useContext(VenueContext);
  const { setState: setFilterState, getState } = useFilterStore();

  const {
    request: searchBalanceMovementsOutRequest,
    error,
    mappedData,
    result: searchBalanceMovementsOutResult = {
      data: [],
      metadata: { total: 0, totalCredits: 0, totalFreeCredits: 0 },
    },
    loading,
  } = useApi({
    api: searchBalanceMovementsOut,
    isArray: true,
    handleOwnError: true,
    mapper: balanceMovementOutListResponse,
  });

  const { modifyFilter, clearFilter, filterState, requestState, modifyFilters, isFilterDirty } =
    useFilter(
      filterName.balanceMovementOut === getState().name && !isEmpty(getState().filter)
        ? getState().filter
        : balanceMovementListFilterState(venue),
      balanceMovementListFilterState(venue)
    );

  const fetchBalanceMovementsOut = useCallback(
    async (requestState) => {
      await searchBalanceMovementsOutRequest(
        mapObject(requestState, balanceMovementOutListFilterRequest)
      );
    },
    [searchBalanceMovementsOutRequest]
  );

  const fetchUpdateStore = useCallback(
    async (requestState, filterState) => {
      setFilterState({
        name: filterName.balanceMovementOut,
        filter: filterState,
      });
      fetchBalanceMovementsOut(requestState);
    },
    [fetchBalanceMovementsOut, setFilterState]
  );

  useMount(() => {
    if (filterName.balanceMovementOut !== getState().name)
      setFilterState({
        name: filterName.balanceMovementOut,
        filter: {},
      });
    fetchBalanceMovementsOut(requestState);

    mixpanel.track(TrackEvent.VisitedPage, {
      Page: lang.balanceMovementsInPage,
    });
  });

  const [hasAppliedFilter, setHasAppliedFilter] = useState(isFilterDirty);

  const renderGuest = useCallback((name, tagUid, tagUseCount, guestId) => {
    let guestTagDisplay = "";

    guestTagDisplay = (
      <div className="flex items-center">
        <Text className="mr-sm group-hover:text-black-light" tagUid>
          {tagUid}
        </Text>
        <Pill type={PillType.Blueish} size="text-xs">
          {tagUseCount} use
        </Pill>
      </div>
    );

    return (
      <ButtonLink
        className="grid grid-cols-1 cursor-pointer group"
        path={Path.GUEST_DETAILS_ID(guestId)}
      >
        {name ? (
          <Text color="text-left text-pelorous group-hover:text-pelorous-dark">{name}</Text>
        ) : (
          <Text color="text-left text-pelorous group-hover:text-pelorous-dark">
            {[lang.guest, " ", guestId]}
          </Text>
        )}
        <div>{guestTagDisplay}</div>
      </ButtonLink>
    );
  }, []);

  const renderDateTime = useCallback((checkInDate, checkInTime) => {
    return (
      <div>
        <Text>{checkInDate}</Text>
        <Text color="text-gray">{checkInTime}</Text>
      </div>
    );
  }, []);

  const prepareBalanceMovementsOutList = useCallback(() => {
    const movements = mappedData.map((balanceMovementOut) => {
      const {
        guestId,
        name,
        tagUid,
        tagUseCount,
        checkOutDate,
        checkOutTime,
        freeCreditsRemoved,
        multiPropertyCreditsOut,
        multiPropertyFreeCreditsOut,
      } = balanceMovementOut;

      return {
        guestId,
        name: renderGuest(name, tagUid, tagUseCount, guestId),
        checkOutDateTime: renderDateTime(checkOutDate, checkOutTime),
        freeCreditsRemoved,
        multiPropertyCreditsOut,
        multiPropertyFreeCreditsOut,
      };
    });

    if (movements.length > 0) {
      movements.unshift({
        guestId: lang.totals,
        name: "",
        checkOutDateTime: "",
        freeCreditsRemoved: "",
        multiPropertyCreditsOut: formatNumberToMoneyWithCurrencySymbol(
          searchBalanceMovementsOutResult.metadata.totalCredits
        ),
        multiPropertyFreeCreditsOut: formatNumberToMoneyWithCurrencySymbol(
          searchBalanceMovementsOutResult.metadata.totalFreeCredits
        ),
      });
    }

    return movements;
  }, [mappedData, renderGuest, renderDateTime, searchBalanceMovementsOutResult]);

  const balanceMovementsOut = useMemo(() => {
    return prepareBalanceMovementsOutList();
  }, [prepareBalanceMovementsOutList]);

  const applyFilterCb = useCallback(
    async (searchKey) => {
      const { requestState, filterState } = modifyFilters({
        page: 1,
        searchKey,
      });
      await fetchUpdateStore(requestState, filterState);
      setHasAppliedFilter(true);
    },
    [fetchUpdateStore, modifyFilters]
  );

  const clearFilterCb = useCallback(() => {
    const { requestState, filterState } = clearFilter();
    fetchUpdateStore(requestState, filterState);
    setHasAppliedFilter(false);
  }, [clearFilter, fetchUpdateStore]);

  const balanceMovementsOutResultContent = useMemo(() => {
    if (hasAppliedFilter) {
      return `${searchBalanceMovementsOutResult.metadata.total} ${pluralize(
        searchBalanceMovementsOutResult.metadata.total,
        lang.searchResult,
        lang.searchResults
      )}`;
    }
    return null;
  }, [searchBalanceMovementsOutResult.metadata.total, hasAppliedFilter]);

  const {
    request: searchBalanceMovementsOutReportExportRequest,
    loading: searchBalanceMovementsOutReportExportLoading,
  } = useApi({
    api: searchBalanceMovementsOutReportExport,
  });

  const exportXlsx = useCallback(() => {
    searchBalanceMovementsOutReportExportRequest(
      mapObject({ ...requestState }, balanceMovementOutListFilterRequest)
    );
  }, [searchBalanceMovementsOutReportExportRequest, requestState]);

  return (
    <ModuleWrapper
      header={
        <HeaderA
          title={lang.balanceMovementsOut}
          description={lang.balanceMovementsDescription}
          button={{
            iconPrefix: <Icon className="mr-sm" name="download" paddingless fontSize={12} />,
            text: lang.exportXlsx,
            loading: searchBalanceMovementsOutReportExportLoading,
            disabled: searchBalanceMovementsOutReportExportLoading,
            onClick: () => {
              exportXlsx();
            },
          }}
          className="mb-md"
        />
      }
    >
      <>
        <MpBalanceMovementOutFilter
          filterState={filterState}
          requestState={requestState}
          modifyFilter={modifyFilter}
          modifyFilters={modifyFilters}
          applyFilter={applyFilterCb}
          clearFilter={clearFilterCb}
        />
        <DataTable
          resultContent={balanceMovementsOutResultContent}
          page={filterState.page}
          pageSize={filterState.pageSize}
          onChangePage={modifyFilters}
          fetchList={fetchUpdateStore}
          total={searchBalanceMovementsOutResult.metadata.total}
          loading={loading}
          columns={columns}
          data={balanceMovementsOut}
          error={error}
          minWidth="800px"
          hasAppliedFilter={isFilterDirty && hasAppliedFilter}
        />
      </>
    </ModuleWrapper>
  );
};

export default MpBalanceMovementOutList;
