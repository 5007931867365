import React, { useCallback, useContext, useMemo, useState } from "react";
import { searchShift, exportRegisterReport } from "apis";
import { ButtonLink, DataTable, Icon, Text, Pill, ModuleWrapper, HeaderA } from "components";
import { VenueContext } from "contexts";
import { useApi, useFilter, useMount, useRouter } from "hooks";
import { shiftListFilterRequest, shiftListResponse } from "mappers";
import { mapObject, pluralize } from "services";
import columns from "./columns";
import ShiftFilter from "./shift-filter";
import { shiftListFilterState } from "./filters";
import { Path } from "paths";
import lang from "translations";
import { mixpanel, TrackEvent } from "mixpanel";
import { PillType } from "enums";
import useFilterStore, { filterName } from "hooks/filterStore";
import { isEmpty } from "lodash";

const ShiftList = () => {
  const { venue } = useContext(VenueContext);
  const { history } = useRouter();
  const { setState: setFilterState, getState } = useFilterStore();

  const {
    request: searchShiftRequest,
    loading: loadingShift,
    result: searchShiftResult = { data: [], metadata: { total: 0, totalClosedShifts: 0 } },
    mappedData,
    error,
  } = useApi({
    api: searchShift,
    isArray: true,
    mapper: shiftListResponse,
  });

  const { modifyFilter, modifyFilters, clearFilter, filterState, requestState, isFilterDirty } =
    useFilter(
      filterName.shiftReport === getState().name && !isEmpty(getState().filter)
        ? getState().filter
        : shiftListFilterState(venue),
      shiftListFilterState(venue)
    );

  const [hasAppliedFilter, setHasAppliedFilter] = useState(isFilterDirty);

  useMount(() => {
    if (filterName.shiftReport !== getState().name)
      setFilterState({
        name: filterName.shiftReport,
        filter: {},
      });
    fetchShifts(requestState);
    setHasAppliedFilter(true);
    mixpanel.track(TrackEvent.VisitedPage, {
      Page: lang.registerReportsPage,
    });
  });

  const fetchShifts = useCallback(
    async (requestState) => {
      await searchShiftRequest(mapObject(requestState, shiftListFilterRequest));
    },
    [searchShiftRequest]
  );

  const fetchUpdateStore = useCallback(
    async (requestState, filterState) => {
      setFilterState({
        name: filterName.shiftReport,
        filter: filterState,
      });
      fetchShifts(requestState);
    },
    [fetchShifts, setFilterState]
  );

  const renderShiftId = useCallback(
    (shiftId) => <ButtonLink path={Path.REGISTER_REPORT_ID(shiftId)}>#{shiftId}</ButtonLink>,
    []
  );

  const renderDeviceName = useCallback(
    (deviceName, deviceImei, deviceSerialNumber) => (
      <div>
        <Text>{deviceName}</Text>
        {deviceImei ? <Text tagUid>{deviceImei}</Text> : <Text>{deviceSerialNumber}</Text>}
      </div>
    ),
    []
  );
  const renderStaffName = useCallback(
    (staffName, staffId) => {
      return (
        <div className="cursor-pointer" onClick={() => history.push(Path.STAFF_ID(staffId))}>
          <Text className="hover:text-gray">{staffName}</Text>
        </div>
      );
    },
    [history]
  );

  const renderStart = useCallback(
    (start) => (
      <div>
        <Text>{start?.date}</Text>
        {start?.time && <Text color="text-gray">{start.time}</Text>}
      </div>
    ),
    []
  );

  const renderEnd = useCallback(
    (end) =>
      end ? (
        <div>
          <Text>{end?.date}</Text>
          {end?.time && <Text color="text-gray">{end.time}</Text>}
        </div>
      ) : (
        <Pill type={PillType.Greenish}>{lang.open}</Pill>
      ),
    []
  );

  const renderLocationName = useCallback(
    (locationName, locationId) => (
      <div className="cursor-pointer" onClick={() => history.push(Path.LOCATION_ID(locationId))}>
        <Text className="hover:text-gray">{locationName}</Text>
      </div>
    ),
    [history]
  );

  const renderTransactions = useCallback(
    ({ transactions, id, startDateTime, endDateTime, isOpen = false }) => (
      <ButtonLink
        newTabPath={
          !isOpen
            ? `${Path.TRANSACTIONS}?shifts=${[
                id,
              ]}&_startDateTime=${startDateTime}&_endDateTime=${endDateTime}`
            : null
        }
        disabled={isOpen}
      >
        {transactions}{" "}
        <Icon
          name="arrow-diagonal-right"
          className={isOpen ? "text-gray text-xxs ml-xs" : "text-blue text-xxs ml-xs"}
        />
      </ButtonLink>
    ),
    []
  );

  const prepareShiftList = useCallback(() => {
    return mappedData.map((shift) => {
      const {
        id,
        deviceName,
        deviceImei,
        deviceSerialNumber,
        staffName,
        staffProfileId,
        start,
        end,
        startDateTime,
        endDateTime,
        location,
        locationId,
        transactions,
      } = shift;

      return {
        shiftId: renderShiftId(id),
        device: renderDeviceName(deviceName, deviceImei, deviceSerialNumber),
        staff: renderStaffName(staffName, staffProfileId),
        start: renderStart(start),
        end: renderEnd(end),
        location: renderLocationName(location, locationId),
        transactions: renderTransactions({
          transactions,
          id,
          startDateTime,
          endDateTime,
          isOpen: !end,
        }),
      };
    });
  }, [
    mappedData,
    renderDeviceName,
    renderStaffName,
    renderShiftId,
    renderStart,
    renderEnd,
    renderLocationName,
    renderTransactions,
  ]);

  const shifts = useMemo(() => {
    return prepareShiftList();
  }, [prepareShiftList]);

  const applyFilterCb = useCallback(
    async (searchKey) => {
      const { requestState, filterState } = modifyFilters({ page: 1, searchKey });
      await fetchUpdateStore(requestState, filterState);

      setHasAppliedFilter(true);
    },
    [fetchUpdateStore, modifyFilters]
  );

  const clearFilterCb = useCallback(() => {
    const { requestState, filterState } = clearFilter();
    fetchUpdateStore(requestState, filterState);

    setHasAppliedFilter(false);
  }, [clearFilter, fetchUpdateStore]);

  const { request: searchShiftExportRequest, loading: searchShiftExportLoading } = useApi({
    api: exportRegisterReport,
  });

  const shiftResultContent = useMemo(() => {
    if (hasAppliedFilter) {
      return `${searchShiftResult.metadata.total} ${pluralize(
        searchShiftResult.metadata.total,
        lang.searchResult,
        lang.searchResults
      )}`;
    }
    return null;
  }, [searchShiftResult.metadata.total, hasAppliedFilter]);

  const sortCb = useCallback(
    ({ value, key }) => {
      const { requestState, filterState } = modifyFilters({ sort: { key, value } });
      fetchUpdateStore(requestState, filterState);
    },
    [fetchUpdateStore, modifyFilters]
  );

  const exportReport = useCallback(() => {
    searchShiftExportRequest(mapObject({ ...requestState }, shiftListFilterRequest));
  }, [searchShiftExportRequest, requestState]);

  return (
    <ModuleWrapper>
      <HeaderA
        title={lang.registerReports}
        description={lang.viewAndExportEachClosedRegister}
        button={{
          loading: searchShiftExportLoading,
          disabled:
            searchShiftExportLoading ||
            !mappedData.length ||
            searchShiftResult?.metadata?.totalClosedShifts === 0,
          text: lang.exportRegisterReport,
          onClick: () => {
            exportReport();
          },
        }}
        className="mb-md"
      />
      <ShiftFilter
        filterState={filterState}
        requestState={requestState}
        modifyFilter={modifyFilter}
        applyFilter={applyFilterCb}
        clearFilter={clearFilterCb}
      />
      <DataTable
        resultContent={shiftResultContent}
        page={filterState.page}
        pageSize={filterState.pageSize}
        onChangePage={modifyFilters}
        fetchList={fetchUpdateStore}
        total={searchShiftResult.metadata.total}
        loading={loadingShift}
        columns={columns}
        data={shifts}
        error={error}
        sort={filterState.sort}
        setSort={sortCb}
        hasAppliedFilter={hasAppliedFilter}
      />
    </ModuleWrapper>
  );
};

export default ShiftList;
