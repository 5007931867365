import moment from "moment";
import { EMode } from "enums";

export const refundClaimReportFilterState = (venue) => {
  const { venueId, refundClaimStartDateTime, refundClaimEndDateTime, defaultMode } = venue;

  let startDate, endDate;

  if (defaultMode === EMode.Event && refundClaimStartDateTime && refundClaimEndDateTime) {
    startDate = moment(refundClaimStartDateTime);
    endDate = moment(refundClaimEndDateTime);
  } else {
    startDate = moment("00:00:00", "HH:mm:ss"); 
    endDate = moment("23:59:00", "HH:mm:ss"); 
  }

  return {
    venueId,
    page: 1,
    pageSize: 20,
    dateRange: [startDate, endDate],
    status: "REFUNDED",
    searchKey: "",
  };
};
