import { searchAdditionalChargeReport, searchAdditionalChargeReportExport } from "apis";
import { DataTable, Icon, Text } from "components/commons";
import { ColumnModal } from "components/modals";
import { VenueContext, StaffContext } from "contexts";
import { useApi, useFilter, useModal, useMount } from "hooks";
import { additionalChargeResponseMapper, additionalChargesReportListRequest } from "mappers";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { formatNumberToMoneyWithCurrencySymbol, mapObject } from "services";
import { additionalChargesReportFilterState } from "./filters";
import AdditionalChargeReportFilter from "./additional-report-filter";

import lang from "translations";
import { HeaderA } from "components/headers";
import { ModuleWrapper } from "components/fragments";
import { mixpanel, TrackEvent } from "mixpanel";

const AdditionalChargeReport = () => {
  const renderDateAndTime = useCallback((dateAndTime) => {
    console.log("renderDateAndTime ", dateAndTime);
    return (
      <div>
        <Text>{dateAndTime?.date}</Text>
        {dateAndTime?.time && <Text color="text-gray">{dateAndTime.time}</Text>}
      </div>
    );
  }, []);

  const renderTransaction = useCallback((transactionId) => {
    return (
      <a href={"/transaction/" + transactionId} target="_blank" rel="noreferrer">
        <Text color="text-pelorous" className="whitespace-nowrap">{`#${transactionId}`}</Text>
      </a>
    );
  }, []);

  const { venue } = useContext(VenueContext);
  const { staff } = useContext(StaffContext);
  const [columnValues, setColumnValues] = useState({
    grossProfit: true,
    sale: true,
    supplyValue: true,
    margin: true,
    discountApplied: true,
    voucherApplied: true,
    guestName: true,
    staffName: true,
    location: true,
  });

  const columnModal = useModal();

  const {
    request: searchChargesReportRequest,
    loading: loadingChargesReports,
    result: searchChargesReportResult = { data: [], metadata: { total: 0 } },
    mappedData: additionalChargeReportsData,
    error: errorChargesReports,
  } = useApi({
    api: searchAdditionalChargeReport,
    isArray: true,
    mapper: additionalChargeResponseMapper,
  });

  const { request: searchChargesReportExportRequest, loading: searchChargesReportExportLoading } =
    useApi({
      api: searchAdditionalChargeReportExport,
    });

  const { modifyFilter, modifyFilters, filterState, requestState, clearFilter } = useFilter(
    additionalChargesReportFilterState(venue)
  );

  useMount(() => {
    fetchAdditionalChargesReports(filterState, requestState);

    mixpanel.track(TrackEvent.VisitedPage, {
      Page: lang.additionalChargeReport,
    });
  });

  const fetchAdditionalChargesReports = useCallback(
    async (filterState, requestState) => {
      await searchChargesReportRequest(
        mapObject({ ...requestState }, additionalChargesReportListRequest)
      );
    },
    [searchChargesReportRequest]
  );

  const renderName = useCallback(
    (name, id) => {
      const linkAdditionalCharge = !(staff?.roles?.includes("FRONT_OFFICE") && staff?.roles?.length === 1);
      return (
        <a
          href={linkAdditionalCharge ? "/setting/additional-charge/edit/" + id : null}
          target="_blank"
          rel="noreferrer"
        >
          <Text color="text-pelorous cursor-pointer" className="break-word">
            {name}
          </Text>
        </a>
      );
    },
    [staff]
  );

  const prepareReports = useCallback(() => {
    return additionalChargeReportsData.map((reportRow) => {
      const {
        id,
        name,
        transactionId,
        transactionDate,
        locationName,
        guest,
        staffName,
        chargeName,
        chargeAmount,
      } = reportRow;
      return {
        id,
        transactionId: renderTransaction(transactionId),
        name: renderName(name, id),
        transactionDate: renderDateAndTime(transactionDate),
        locationName,
        guestName: guest.name,
        staffName,
        chargeAmount: formatNumberToMoneyWithCurrencySymbol(chargeAmount),
        chargeName,
      };
    });
  }, [additionalChargeReportsData, renderDateAndTime, renderName, renderTransaction]);

  const chargesReports = useMemo(() => {
    return prepareReports();
  }, [prepareReports]);

  const clearFilterCb = useCallback(
    (filterState, requestState) => {
      fetchAdditionalChargesReports(filterState, requestState);
    },
    [fetchAdditionalChargesReports]
  );

  const applyFilterCb = useCallback(
    async (searchKey) => {
      const { filterState, requestState } = modifyFilters({
        page: 1,
        searchKey,
        sort: { key: "t.deviceUpdatedDate", value: "desc" },
      });
      await fetchAdditionalChargesReports(filterState, requestState);
    },
    [fetchAdditionalChargesReports, modifyFilters]
  );

  const sortCb = useCallback(
    ({ value, key }) => {
      const { filterState, requestState } = modifyFilters({ sort: { key, value } });
      fetchAdditionalChargesReports(filterState, requestState);
    },
    [fetchAdditionalChargesReports, modifyFilters]
  );

  const exportXlsx = useCallback(() => {
    searchChargesReportExportRequest(
      mapObject({ ...requestState }, additionalChargesReportListRequest)
    );
  }, [searchChargesReportExportRequest, requestState]);

  const prepareColumns = useCallback(() => {
    return [
      {
        key: "transactionId",
        text: lang.transaction,
      },
      {
        key: "transactionDate",
        text: lang.date,
        sort: true,
      },
      {
        key: "locationName",
        text: lang.location,
        sort: true,
      },
      {
        key: "guestName",
        text: lang.guest,
      },
      {
        key: "staffName",
        text: lang.staffName,
      },
      {
        key: "name",
        text: lang.charge,
      },
      {
        key: "chargeAmount",
        text: lang.chargeAmount,
        align: "right",
      },
    ];
  }, []);

  const columns = useMemo(() => {
    return prepareColumns();
  }, [prepareColumns]);

  const onChangeColumnCb = useCallback(
    (name, { value }) => {
      setColumnValues({ ...columnValues, [name]: value });
    },
    [columnValues]
  );

  const noResult = useMemo(() => {
    return (
      <div className="pt-lg">
        <p className="text-xl text-gray-600">{lang.noReportsFound}</p>
        <p className="text-md text-gray-400">{lang.weDidNotFoundReports}</p>
      </div>
    );
  }, []);

  return (
    <ModuleWrapper>
      <HeaderA
        title={lang.additionalChargeReport}
        button={{
          iconPrefix: <Icon className="mr-sm" name="download" paddingless fontSize={12} />,
          text: lang.exportXlsx,
          loading: searchChargesReportExportLoading,
          disabled: searchChargesReportExportLoading,
          onClick: () => {
            exportXlsx();
          },
        }}
        className="mb-md"
      />

      <AdditionalChargeReportFilter
        filterState={filterState}
        requestState={requestState}
        modifyFilter={modifyFilter}
        clearFilter={clearFilter}
        onClear={clearFilterCb}
        onApply={applyFilterCb}
      />

      {chargesReports.length > 0 || loadingChargesReports ? (
        <DataTable
          page={filterState.page}
          pageSize={filterState.pageSize}
          onChangePage={modifyFilters}
          fetchList={fetchAdditionalChargesReports}
          total={searchChargesReportResult.metadata.total}
          loading={loadingChargesReports}
          columns={columns}
          data={chargesReports}
          error={errorChargesReports}
          sort={filterState.sort}
          setSort={sortCb}
        />
      ) : (
        noResult
      )}

      <ColumnModal
        {...columnModal}
        columns={[
          [
            { text: lang.grossProfit, name: "grossProfit" },
            { text: "Sales", name: "sale" },
          ],
          [
            { text: lang.supplyValue, name: "supplyValue" },
            { text: lang.margin, name: "margin" },
          ],
          [
            { text: lang.discountApplied, name: "discountApplied" },
            { text: lang.voucherApplied, name: "voucherApplied" },
          ],
          [
            { text: lang.guestName, name: "guestName" },
            { text: lang.staffName, name: "staffName" },
          ],
          [{ text: lang.location, name: "location" }],
        ]}
        columnValues={columnValues}
        onChange={onChangeColumnCb}
      />
    </ModuleWrapper>
  );
};

export default AdditionalChargeReport;
