import React, { useCallback, useContext, useMemo, useState } from "react";
import { searchTransaction, exportTransaction, exportTransactionTax } from "apis";
import { Pill, Text, DataTable, ButtonLink, Tooltip } from "components/commons";
import { VenueContext } from "contexts";
import { PillType, TransactionType, PaymentMethod } from "enums";
import { useApi, useFilter, useMount, useRouter } from "hooks";
import { transactionListFilterRequest, transactionListResponse } from "mappers";
import { mapObject, pluralize } from "services";
import columns from "./columns";
import { prettifyTransactionType } from "services/pretty.service";
import TransactionFilter from "./transaction-filter";
import { transactionListFilterState } from "./filters";
import lang from "translations";
import { Path } from "paths";
import { HeaderA } from "components/headers";
import { ModuleWrapper } from "components/fragments";
import { mixpanel, TrackEvent } from "mixpanel";
import { ExportOutlined } from "@ant-design/icons";
import useFilterStore, { filterName } from "hooks/filterStore";
import { isEmpty } from "lodash";
import { getPillTypeOfTransactionType } from "services/styling.service";

const TransactionList = () => {
  const { venue } = useContext(VenueContext);
  const { history, pathname, query } = useRouter();
  const { guestCheckinId: filteredGuestCheckinId } = query || {};
  const [guestCheckinId, setGuestCheckinId] = useState(null);
  const { setState: setFilterState, getState } = useFilterStore();

  const {
    request: searchTransactionRequest,
    loading: loadingTransaction,
    result: searchTransactionResult = { data: [], metadata: { total: 0 } },
    error,
    mappedData,
  } = useApi({
    api: searchTransaction,
    isArray: true,
    mapper: transactionListResponse,
    handleOwnError: true,
  });

  const { modifyFilter, modifyFilters, clearFilter, filterState, requestState, isFilterDirty } =
    useFilter(
      !isEmpty(query)
        ? transactionListFilterState(venue, {
            query,
            guestCheckinId: filteredGuestCheckinId,
          })
        : filterName.transaction === getState().name && !isEmpty(getState().filter)
        ? getState().filter
        : transactionListFilterState(venue),
      transactionListFilterState(venue)
    );

  const [hasAppliedFilter, setHasAppliedFilter] = useState(isFilterDirty);

  useMount(() => {
    if (filterName.transaction !== getState().name)
      setFilterState({
        name: filterName.transaction,
        filter: {},
      });
    setGuestCheckinId(filteredGuestCheckinId);
    fetchTransactions(requestState);

    if (Object.keys(query).length > 0 && !filteredGuestCheckinId) {
      history.replace(pathname);
    }
    mixpanel.track(TrackEvent.VisitedPage, {
      Page: lang.transactionsPage,
    });
  });

  const fetchTransactions = useCallback(
    async (requestState) => {
      const params = mapObject(requestState, transactionListFilterRequest);
      if (!requestState.dateRange[0]) {
        params.startDateTime = null;
        params.endDateTime = null;
      }
      if (
        (guestCheckinId || filteredGuestCheckinId) &&
        [guestCheckinId, filteredGuestCheckinId].includes(params.searchKey)
      ) {
        params.guestCheckinId = guestCheckinId || filteredGuestCheckinId;
        params.searchKey = null;
      }
      await searchTransactionRequest(params);
    },
    [searchTransactionRequest, guestCheckinId, filteredGuestCheckinId]
  );

  const fetchUpdateStore = useCallback(
    async (requestState, filterState) => {
      setFilterState({
        name: filterName.transaction,
        filter: filterState,
      });
      fetchTransactions(requestState);
    },
    [fetchTransactions, setFilterState]
  );

  const renderTransactionId = useCallback(
    (transactionId) => (
      <ButtonLink path={Path.TRANSACTION_ID(transactionId)}>#{transactionId}</ButtonLink>
    ),
    []
  );

  const renderDateCreated = useCallback(
    (dateCreated) => (
      <div>
        <Text className="w-20 max-w-20 md:w-32.5 md:max-w-32.5">{dateCreated?.date}</Text>
        {dateCreated?.time && <Text color="text-gray">{dateCreated.time}</Text>}
      </div>
    ),
    []
  );

  const renderGuestName = useCallback(
    (guestName, userTagUid, userTagUseCount, guestId, paymentMethod, type) => {
      const isPaidByCashOrCard = [
        PaymentMethod.Cash,
        PaymentMethod.CreditDebit,
        PaymentMethod.Credit,
        PaymentMethod.Others,
        PaymentMethod.EWallet,
      ].includes(paymentMethod);
      if (isPaidByCashOrCard && type === TransactionType.SALE) {
        return (
          <div>
            <Text strong>-</Text>
          </div>
        );
      }
      if (type === TransactionType.TOPUP && !userTagUid) {
        return (
          <div>
            <Text strong>-</Text>
          </div>
        );
      }

      return (
        <div>
          {guestName && (
            <ButtonLink className="cursor-pointer group" path={Path.GUEST_DETAILS_ID(guestId)}>
              <Text className="group-hover:text-gray">{guestName}</Text>
            </ButtonLink>
          )}
          <Text tagUid className="group-hover:text-gray">
            {userTagUid}
          </Text>
          <Pill type={PillType.Blueish} size="text-xs">
            {`${userTagUseCount}`}
          </Pill>
        </div>
      );
    },
    []
  );

  const renderLocationName = useCallback(
    (locationName, locationId) => (
      <ButtonLink className="cursor-pointer" path={Path.LOCATION_ID(locationId)}>
        <Text className="hover:text-gray min-w-25 md:min-w-37.5">{locationName}</Text>
      </ButtonLink>
    ),
    []
  );

  const renderStaffName = useCallback(
    (staffName, staffTagUid, staffProfileId) => (
      <div>
        <ButtonLink
          className="cursor-pointer group"
          // onClick={() => history.push(Path.STAFF_ID(staffProfileId))}
          path={Path.STAFF_ID(staffProfileId)}
        >
          <Text className="group-hover:text-gray w-20 max-w-20 md:w-32.5 md:max-w-32.5">
            {staffName}
          </Text>
        </ButtonLink>
        <Text tagUid className="group-hover:text-gray w-20 max-w-20 md:w-32.5 md:max-w-32.5">
          {staffTagUid}
        </Text>
      </div>
    ),
    []
  );

  const renderTransactionType = useCallback((type, isVoided, isFailed, isCheckout) => {
    let pillType = getPillTypeOfTransactionType(type);
    if (isFailed) {
      pillType = PillType.Red;
    } else if (isVoided) {
      pillType = PillType.Gray;
    }

    const isGreen = pillType === PillType.Green;
    const txt = `${isFailed ? `${lang.failed} ` : ""} ${prettifyTransactionType(type)} ${
      !isVoided ? "" : isFailed ? lang.void : lang.voided
    }`;
    return (
      <div className="flex items-center">
        <Pill size="text-xs" type={pillType}>
          {txt}
        </Pill>
        {type === TransactionType.TOPUP && isCheckout && isGreen && (
          <Tooltip title="Checkout Payment Collected">
            <ExportOutlined className="cursor-pointer" style={{ color: "gray" }} />
          </Tooltip>
        )}
        {type === TransactionType.RETURN && isCheckout && isGreen && (
          <Tooltip title="Checkout Credits Returned">
            <ExportOutlined className="cursor-pointer" style={{ color: "gray" }} />
          </Tooltip>
        )}
      </div>
    );
  }, []);

  const renderPaidAmount = useCallback(
    (paidAmount, type) => (
      <div>
        <Text
          className="min-w-25 md:min-w-37.5 text-right"
          danger={type === TransactionType.RETURN}
        >
          {paidAmount}
        </Text>
      </div>
    ),
    []
  );

  const renderItems = useCallback((items, type) => {
    const tooltipList = [];
    const productList = [];
    const deletedProductList = [];

    if (items.length) {
      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        const { name, deleted } = item;

        if (i < 4) {
          if (deleted) {
            deletedProductList.push(name);
          } else {
            productList.push(name);
          }
        }

        tooltipList.push(name);
      }

      if (items.length > 4) {
        productList.push(`${items.length - 4} more.`);
      }

      return (
        <Tooltip
          title={
            <Text breakAll color="text-white" fontWeight="font-semibold">
              {tooltipList.join(", ")}
            </Text>
          }
        >
          <div className="min-w-25 md:min-w-42.5">
            <Text color="text-gray">{deletedProductList.join(", ")}</Text>
            <Text>{productList.join(", ")}</Text>
          </div>
        </Tooltip>
      );
    }
  }, []);

  const prepareTransactionList = useCallback(() => {
    return mappedData.map((transaction) => {
      const {
        transactionId,
        dateCreated,
        guestName,
        userTagUid,
        locationName,
        locationId,
        staffName,
        staffTagUid,
        staffProfileId,
        transactionType,
        paidAmount,
        guestId,
        items,
        qty,
        paymentMethod,
        isCheckout,
        userTagUseCount,
        isVoided,
        isFailed,
      } = transaction;

      return {
        transactionId: renderTransactionId(transactionId),
        dateCreated: renderDateCreated(dateCreated),
        guest: renderGuestName(
          guestName,
          userTagUid,
          userTagUseCount,
          guestId,
          paymentMethod,
          transactionType
        ),
        locationName: renderLocationName(locationName, locationId),
        staffName: renderStaffName(staffName, staffTagUid, staffProfileId),
        transactionType: renderTransactionType(transactionType, isVoided, isFailed, isCheckout),
        paidAmount: renderPaidAmount(paidAmount, transactionType),
        items: renderItems(items, transactionType),
        quantity: (
          <div>
            <Text>{qty}</Text>
          </div>
        ),
      };
    });
  }, [
    mappedData,
    renderTransactionId,
    renderDateCreated,
    renderGuestName,
    renderLocationName,
    renderTransactionType,
    renderPaidAmount,
    renderStaffName,
    renderItems,
  ]);

  const transactions = useMemo(() => {
    return prepareTransactionList();
  }, [prepareTransactionList]);

  const applyFilterCb = useCallback(
    async (searchKey) => {
      const { requestState, filterState } = modifyFilters({ page: 1, searchKey });
      await fetchUpdateStore(requestState, filterState);

      setHasAppliedFilter(true);
    },
    [fetchUpdateStore, modifyFilters]
  );

  const clearFilterCb = useCallback(() => {
    const { requestState, filterState } = clearFilter();
    fetchUpdateStore(requestState, filterState);

    setHasAppliedFilter(false);
  }, [clearFilter, fetchUpdateStore]);

  const transactionResultContent = useMemo(() => {
    if (hasAppliedFilter) {
      return `${searchTransactionResult.metadata.total} ${pluralize(
        searchTransactionResult.metadata.total,
        lang.searchResult,
        lang.searchResults
      )}`;
    }
    return null;
  }, [searchTransactionResult.metadata.total, hasAppliedFilter]);

  const sortCb = useCallback(
    ({ value, key }) => {
      const { requestState } = modifyFilters({ sort: { key, value } });
      fetchUpdateStore(requestState);
    },
    [fetchUpdateStore, modifyFilters]
  );

  const { request: exportTransactionsRequest, loading: exportTransactionsLoading } = useApi({
    api: exportTransaction,
  });

  const { request: exportTransactionsTaxRequest, loading: exportTransactionsTaxLoading } = useApi({
    api: exportTransactionTax,
  });

  const exportReport = useCallback(() => {
    var request = {
      ...mapObject({ ...requestState }, transactionListFilterRequest),
      venueId: venue.venueId,
      guestCheckinId: guestCheckinId,
    };
    delete request.page;
    delete request.pageSize;
    exportTransactionsRequest(request);
  }, [exportTransactionsRequest, requestState, venue.venueId, guestCheckinId]);

  const exportTaxReport = useCallback(() => {
    var request = {
      ...mapObject({ ...requestState }, transactionListFilterRequest),
      venueId: venue.venueId,
    };
    delete request.page;
    delete request.pageSize;
    exportTransactionsTaxRequest(request);
  }, [exportTransactionsTaxRequest, requestState, venue.venueId]);

  const getHeader = useMemo(() => {
    const options = [lang.exportTransactions, lang.exportTaxReport];

    const handleExportClick = (selectedIndex) => {
      if (selectedIndex === 0) {
        exportReport();
      } else if (selectedIndex === 1) {
        exportTaxReport();
      }
    };

    return (
      <HeaderA
        title={lang.transactions}
        description={lang.viewEveryTransactions}
        buttonsGroup={{
          label: "Export",
          options: options,
          loading: exportTransactionsLoading || exportTransactionsTaxLoading,
          disabled:
            exportTransactionsLoading ||
            exportTransactionsTaxLoading ||
            !searchTransactionResult.metadata.total,
          handleClick: (selectedIndex) => {
            handleExportClick(selectedIndex);
          },
        }}
        className="mb-md"
      />
    );
  }, [
    exportTransactionsLoading,
    exportTransactionsTaxLoading,
    searchTransactionResult.metadata.total,
    exportReport,
    exportTaxReport,
  ]);

  return (
    <ModuleWrapper>
      {getHeader}

      <TransactionFilter
        filterState={filterState}
        requestState={requestState}
        modifyFilter={modifyFilter}
        applyFilter={applyFilterCb}
        clearFilter={clearFilterCb}
      />

      <DataTable
        resultContent={transactionResultContent}
        page={filterState.page}
        pageSize={filterState.pageSize}
        onChangePage={modifyFilters}
        fetchList={fetchUpdateStore}
        total={searchTransactionResult.metadata.total}
        loading={loadingTransaction}
        columns={columns}
        data={transactions}
        error={error}
        sort={filterState.sort}
        setSort={sortCb}
        minWidth={0}
        hasAppliedFilter={isFilterDirty && hasAppliedFilter}
      />
    </ModuleWrapper>
  );
};

export default TransactionList;
