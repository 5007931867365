import { TapStatus, TransactionStatus } from "enums";
import moment from "moment";
import { isEmpty } from "lodash";
import { transactionDateStore } from "hooks";

export const transactionListFilterState = (venue, initial = {}) => {
  const { venueId, startDate, endDate } = transactionDateStore(venue);
  const { query, guestCheckinId } = initial;
  let state = {
    venueId,
    page: 1,
    pageSize: 20,
    searchKey: "",
    dateRange: guestCheckinId
      ? [null, null]
      : [startDate, endDate],
    startDateTime: null,
    endDateTime: null,
    locations: [],
    shifts: [],
    staffs: [],
    transactionTypes: [],
    transactionStatus: guestCheckinId ? null : TransactionStatus.SUCCESS,
    tapStatus: guestCheckinId ? TapStatus.Success : null,
    externalPaymentStatus: [],
    sort: { key: "dateCreated", value: "desc" },
  };

  if (isEmpty(query)) return { ...state };

  let queryState = {};
  if (query.hasOwnProperty("_startDateTime") && query.hasOwnProperty("_endDateTime")) {
    queryState = {
      ...queryState,
      dateRange: [moment(query._startDateTime), moment(query._endDateTime)],
    };
  }

  if (query.hasOwnProperty("shifts")) {
    queryState = { ...queryState, shifts: query.shifts || state.shifts };
  }

  if (query.hasOwnProperty("guestCheckinId")) {
    state.searchKey = query.guestCheckinId;
  }

  return { ...state, ...queryState };
};
