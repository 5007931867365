import { transactionDateStore } from "hooks";

export const voucherReportFilterState = (venue) => {
  const { venueId, startDate, endDate } = transactionDateStore(venue);
  return {
    venueId,
    page: 1,
    pageSize: 20,
    searchKey: "",
    dateRange: [startDate, endDate],
    locations: [],
    voucher: null,
    sort: { key: "supplyValue", value: "desc" },
  };
};
