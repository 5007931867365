import { formatNumberToMoney, parseMoneyToNumber } from "services/money.service";
import { toApiDateTimeFormat } from "services/date.service";
import moment from "moment";

export const creditLimitRequest = {
  postPaidBillingLimit: {
    transform: ({ src }) => {
      if (src.hasCreditLimit) {
        return Number(parseMoneyToNumber(src.creditLimit).value) || null;
      }
      return null;
    },
  },
  topupCreditLimit: {
    transform: () => {
      return null;
    },
  },
  creditExpirationType: {
    key: "creditExpirationType",
  },
  creditExpirationCount: {
    key: "creditExpirationCount",
    transform: ({ src }) => {
      if (src.creditExpirationType === "NEVER_EXPIRES_AFTER_CHECKOUT") {
        return null;
      }
      return src.creditExpirationCount;
    },
  },
  creditExpirationSpan: {
    key: "creditExpirationSpan",
    transform: ({ src }) => {
      if (src.creditExpirationType === "NEVER_EXPIRES_AFTER_CHECKOUT") {
        return null;
      }
      return src.creditExpirationSpan;
    },
  },
  creditResetExpiration: {
    key: "creditResetExpiration",
    transform: ({ src }) => {
      if (src.creditExpirationType === "NEVER_EXPIRES_AFTER_CHECKOUT") {
        return null;
      }
      return src.creditResetExpiration;
    },
  },
  isCreditResetExpirationActive: {
    key: "isCreditResetExpirationActive",
    transform: ({ src }) => {
      if (src.creditExpirationType === "NEVER_EXPIRES_AFTER_CHECKOUT") {
        return null;
      }
      return src.isCreditResetExpirationActive;
    },
  },
  venueId: {
    key: "venueId",
  },
};

export const venueResponse = {
  venueId: {
    key: "venueId",
  },
  postPaidBillingLimit: {
    key: "postPaidBillingLimit",
  },
  postPaidBillingLimitMoney: {
    transform: ({ src }) => {
      return formatNumberToMoney(src.postPaidBillingLimit);
    },
  },
  venueName: {
    key: "venueName",
  },
  currencyCode: {
    key: "currencyCode",
  },
  currencySymbol: {
    key: "currencySymbol",
  },
  keepWristbandAllowed: {
    key: "keepWristbandAllowed",
  },
  defaultMode: { key: "defaultVenueMode" },
  isEnableExitAccessPoint: { key: "isEnableExitAccessPoint" },
  registerForRefund: { key: "registerForRefund" },
  enableKiosk: { key: "enableKiosk" },
  dateRange: {
    transform: ({ src }) => {
      const { eventStartDateTime, eventEndDateTime } = src;

      return [
        eventStartDateTime ? moment(eventStartDateTime) : null,
        eventEndDateTime ? moment(eventEndDateTime) : null,
      ];
    },
  },
  refundDateRange: {
    transform: ({ src }) => {
      const { refundClaimStartDateTime, refundClaimEndDateTime } = src;

      return [
        refundClaimStartDateTime ? moment(refundClaimStartDateTime) : null,
        refundClaimEndDateTime ? moment(refundClaimEndDateTime) : null,
      ];
    },
  },
  transactionDateRange: {
    transform: ({ src }) => {
      const { transactionStartDateTime, transactionEndDateTime } = src;

      return [
        transactionStartDateTime ? moment(transactionStartDateTime) : null,
        transactionEndDateTime ? moment(transactionEndDateTime) : null,
      ];
    },
  },
};

export const venueRequest = {
  venueId: {
    key: "venueId",
  },
  venueName: {
    key: "name",
  },
  currencyCode: {
    key: "currency",
  },
  demo: {
    key: "demo",
  },
  businessName: {
    key: "businessName",
  },
  businessAddress: {
    key: "businessAddress",
  },
  businessContactNumber: {
    key: "businessContactNumber",
  },
  businessTaxNumber: {
    key: "businessTaxNumber",
  },
  countryName: {
    key: "countryName",
  },
  logoLink: {
    key: "logoLink",
  },
  denominations: {
    key: "denominations",
  },
  defaultMewsOutletId: {
    key: "defaultMewsOutletId",
  },
  defaultMewsServiceId: {
    key: "defaultMewsServiceId",
  },
  mewsTopUpOutletId: {
    key: "mewsTopUpOutletId",
  },
  mewsCashBackOutletId: {
    key: "mewsCashBackOutletId",
  },
  mewsCreditsReceivedOutletId: {
    key: "mewsCreditsReceivedOutletId",
  },
  mewsCreditsKeptOutletId: {
    key: "mewsCreditsKeptOutletId",
  },
  clearFields: {
    key: "clearFields",
  },
  defaultMode: { key: "defaultMode" },
  registerForRefund: { key: "registerForRefund" },
  enableKiosk: { key: "enableKiosk" },

  eventStartDateTime: {
    transform: ({ src }) => {
      if (!src?.dateRange?.[0]) return null;
      return toApiDateTimeFormat(src?.dateRange?.[0]);
    },
  },
  eventEndDateTime: {
    transform: ({ src }) => {
      if (!src?.dateRange?.[1]) return null;
      return toApiDateTimeFormat(src?.dateRange?.[1], true);
    },
  },
  refundClaimStartDateTime: {
    transform: ({ src }) => {
      if (!src?.refundDateRange?.[0]) return null;
      return toApiDateTimeFormat(src?.refundDateRange?.[0]);
    },
  },
  refundClaimEndDateTime: {
    transform: ({ src }) => {
      if (!src?.refundDateRange?.[1]) return null;
      return toApiDateTimeFormat(src?.refundDateRange?.[1], true);
    },
  },
  transactionStartDateTime: {
    transform: ({ src }) => {
      if (!src?.transactionDateRange?.[0]) return null;
      return toApiDateTimeFormat(src?.transactionDateRange?.[0]);
    },
  },
  transactionEndDateTime: {
    transform: ({ src }) => {
      if (!src?.transactionDateRange?.[1]) return null;
      return toApiDateTimeFormat(src?.transactionDateRange?.[1], true);
    },
  },
  eventTimezone: {
    key: "eventTimezone",
  },
  enableGHL: { key: "enableGHL" },
};

export const checklistRequest = {
  addedProduct: {
    key: "addedProduct",
  },
  addedSupplyItem: {
    key: "addedSupplyItem",
  },
  invitedStaff: {
    key: "invitedStaff",
  },
  loggedInApp: {
    key: "loggedInApp",
  },
};
