import React, { useCallback, useMemo, useRef, useState } from "react";
import { DatePicker } from "antd";
import styles from "./range-picker.module.scss";
import classnames from "classnames";
import { Button } from "components/commons";
import lang from "translations";
import moment from "moment";
import useMobile from "hooks/useMobile";

const { RangePicker: AntRangePicker } = DatePicker;

const RangePicker = ({
  name,
  className,
  format = "MMM DD, YYYY",
  value,
  onChange,
  enableTime = true,
  footer,
  disabledDateRange = false,
}) => {
  const rangePickerRef = useRef();
  const [select, setSelect] = useState([]);
  const isMobile = useMobile();

  const rangePickerState = useMemo(() => {
    if (!value[0] || !value[1]) {
      return "CUSTOM";
    }
    if (!footer) {
      if (
        value[0].isSame(moment().startOf("day"), "minute") &&
        value[1].isSame(moment().endOf("day"), "minute")
      ) {
        return "TODAY";
      }
      if (
        value[0].isSame(moment().startOf("week"), "minute") &&
        value[1].isSame(moment().endOf("day"), "minute")
      ) {
        return "WEEK";
      }
      if (
        value[0].isSame(moment().startOf("month"), "minute") &&
        value[1].isSame(moment().endOf("day"), "minute")
      ) {
        return "MONTH";
      }
      return "CUSTOM";
    }
    return "CUSTOM";
  }, [value, footer]);

  const todayCb = useCallback(() => {
    if (onChange) {
      onChange(name, [moment().startOf("day"), moment().endOf("day")]);
      rangePickerRef.current.blur();
    }
  }, [onChange, name]);

  const thisWeekCb = useCallback(() => {
    if (onChange) {
      onChange(name, [moment().startOf("week"), moment().endOf("day")]);
      rangePickerRef.current.blur();
    }
  }, [onChange, name]);

  const thisMonthCb = useCallback(() => {
    if (onChange) {
      onChange(name, [moment().startOf("month"), moment().endOf("day")]);
      rangePickerRef.current.blur();
    }
  }, [onChange, name]);

  const onChangeCb = useCallback(
    (v) => {
      if (onChange) {
        if (enableTime) {
          const time = value[0] ? value : [moment().startOf("day"), moment().endOf("day")];
          let dateRangeValue = [
            v[0].set({ hour: time[0].get("hour"), minute: time[0].get("minute") }),
            v[1].set({ hour: time[1].get("hour"), minute: time[1].get("minute") }),
          ];

          if (v[0] && !v[1]) {
            dateRangeValue[1] = v[0].set({
              hour: time[1].get("hour"),
              minute: time[1].get("minute"),
            });
          }

          if (v[1] && !v[0]) {
            dateRangeValue[0] = v[1].set({
              hour: time[1].get("hour"),
              minute: time[1].get("minute"),
            });
          }

          // FYI: removed this for now since it creates confusion when we automatically change the date range.
          // product team will check for a better approach to limit results to 90 days
          // - russell

          // const tooLate =
          //   dateRangeValue[0] && dateRangeValue[1].diff(dateRangeValue[0], "days") > 90;
          // const tooEarly =
          //   dateRangeValue[1] && dateRangeValue[1].diff(dateRangeValue[0], "days") > 90;
          // if (tooLate || tooEarly) {
          //   dateRangeValue = [dateRangeValue[0], dateRangeValue[0]];
          // }
          onChange(name, dateRangeValue);
          // setSelect(tooLate || tooEarly ? [] : dateRangeValue);
          setSelect(dateRangeValue);
        }
      }
    },
    [onChange, name, value, enableTime]
  );

  // const disabledDateCb = useCallback(
  //   (current) => {
  //     if (!select || select.length === 0) {
  //       return false;
  //     }
  //     const tooLate = select[0] && current.diff(select[0], "days") > 90;
  //     const tooEarly = select[1] && select[1].diff(current, "days") > 90;
  //     return !!tooEarly || !!tooLate;
  //   },
  //   [select]
  // );

  const disabledDateCb = useCallback(
    (current) => {
      // If a custom disabledDateRange is provided, apply that logic first
      if (disabledDateRange) {
        const { start, end } = disabledDateRange;
        if (start && current.isBefore(start, "day")) {
          return true;
        }
        if (end && current.isAfter(end, "day")) {
          return true;
        }
      }

      // Then apply the 90-day validation logic
      if (!select || select.length === 0) {
        return false;
      }
      const tooLate = select[0] && current.diff(select[0], "days") > 90;
      const tooEarly = select[1] && select[1].diff(current, "days") > 90;
      return tooEarly || tooLate;
    },
    [select, disabledDateRange] // Depend on select and disabledDateRange
  );

  return (
    <div>
      <AntRangePicker
        ref={rangePickerRef}
        disabledDate={disabledDateCb}
        name={name}
        onClick={(e) => {
          console.log(e);
        }}
        className={classnames(styles.rangePicker, className)}
        format={format}
        defaultValue={!value[0] ? [null, null] : [moment().startOf("day"), moment().endOf("day")]}
        value={value}
        onChange={onChangeCb}
        allowClear={false}
        inputReadOnly
        separator={!value[0] ? null : <div className="text-sm">{lang.to}</div>}
        onOpenChange={() => setSelect([])}
        placeholder={["Set Date"]}
        renderExtraFooter={
          footer
            ? footer
            : () => (
                <div className={classnames("flex items-center py-2 md:p-md", styles.footer)}>
                  <Button
                    className={classnames({ [`${styles.active}`]: rangePickerState === "TODAY" })}
                    onClick={todayCb}
                  >
                    {lang.today}
                  </Button>
                  <Button
                    className={classnames({ [`${styles.active}`]: rangePickerState === "WEEK" })}
                    onClick={thisWeekCb}
                  >
                    {lang.thisWeek}
                  </Button>
                  <Button
                    className={classnames({ [`${styles.active}`]: rangePickerState === "MONTH" })}
                    onClick={thisMonthCb}
                  >
                    {lang.thisMonth}
                  </Button>
                  <Button
                    className={classnames("cursor-default", {
                      [`${styles.active}`]: rangePickerState === "CUSTOM",
                    })}
                  >
                    {lang.custom}
                  </Button>
                </div>
              )
        }
        showTime={
          isMobile && {
            showHour: false,
            showMinute: false,
            showSecond: false,
          }
        }
      />
    </div>
  );
};

export default RangePicker;
