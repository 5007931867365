import { searchGuestRefundsReport, searchGuestRefundsReportExport } from "apis";
import { Panel, DataTable, Icon, Text, Title } from "components/commons";
import { VenueContext } from "contexts";
import { useApi, useFilter, useMount } from "hooks";
import { guestRefundsReportRequest } from "mappers";
import React, { useCallback, useContext, useMemo } from "react";
import { mapObject } from "services";
import { HeaderA } from "components/headers";
import { guestRefundReportFilterState } from "./filters";
import GuestRefundReportFilter from "./guest-refund-report-filter";
import { GuestRefundReportColumns } from "./columns";
import lang from "translations";
import { ModuleWrapper } from "components/fragments";
import { mixpanel, TrackEvent } from "mixpanel";
import { formatNumberToMoneyWithCurrencySymbol } from "services/money.service";
import { formatDate, formatTagUIds } from "services";
import { DateTime } from "enums";

const GuestRefundsReport = () => {
  const { venue } = useContext(VenueContext);
  const { eventStartDateTime, eventEndDateTime, refundClaimStartDateTime, refundClaimEndDateTime } =
    venue;
  const {
    request: searchGuestRefundsReportRequest,
    loading: loadingGuestRefundsReports,
    result: searchGuestRefundsReportResult = {
      data: { content: [] },
      metadata: { total: 0 },
      totalElements: 0,
    },
    error: errorGuestRefundsReports,
  } = useApi({
    api: searchGuestRefundsReport,
    isArray: false,
    params: {
      refundClaimStartDateTime,
      refundClaimEndDateTime
    },
  });

  const {
    request: searchGuestRefundsReportExportRequest,
    loading: searchGuestRefundsReportExportLoading,
  } = useApi({
    api: searchGuestRefundsReportExport,
    params: {
      reportType: "REFUND_REPORT_REGISTRATION"
    },
  });

  const { modifyFilter, modifyFilters, filterState, requestState, clearFilter } = useFilter(
    guestRefundReportFilterState(venue)
  );

  useMount(() => {
    fetchGuestRefundsReports(filterState, requestState);

    mixpanel.track(TrackEvent.VisitedPage, {
      Page: lang.GuestRefundsReport,
    });
  });

  const fetchGuestRefundsReports = useCallback(
    (filterState, requestState) => {
      searchGuestRefundsReportRequest(mapObject({ ...requestState }, guestRefundsReportRequest));
    },
    [searchGuestRefundsReportRequest]
  );

  const clearFilterCb = useCallback(
    (filterState, requestState) => {
      fetchGuestRefundsReports(filterState, requestState);
    },
    [fetchGuestRefundsReports]
  );

  const applyFilterCb = useCallback(
    (searchKey) => {
      const { filterState, requestState } = modifyFilters({
        page: 1,
        searchKey,
      });
      fetchGuestRefundsReports(filterState, requestState);
    },
    [fetchGuestRefundsReports, modifyFilters]
  );

  const sortCb = useCallback(
    ({ value, key }) => {
      const { filterState, requestState } = modifyFilters({ sort: { key, value } });
      fetchGuestRefundsReports(filterState, requestState);
    },
    [fetchGuestRefundsReports, modifyFilters]
  );

  const exportXlsx = useCallback(() => {
    searchGuestRefundsReportExportRequest(
      mapObject({ ...requestState }, guestRefundsReportRequest)
    );
  }, [searchGuestRefundsReportExportRequest, requestState]);

  const noResult = useMemo(() => {
    return (
      <div className="pt-lg">
        <p className="text-xl text-gray-600">{lang.noReportsFound}</p>
        <p className="text-md text-gray-400">{lang.weDidNotFoundReports}</p>
      </div>
    );
  }, []);

  const GuestRefundsReportsData = useMemo(() => {
    return searchGuestRefundsReportResult.data?.content?.map(
      ({ guestEmailOrPhoneNumber, tagUid, tagBalance }) => {
        return {
          guestEmailOrPhoneNumber,
          tagUid: formatTagUIds(tagUid),
          tagBalance: formatNumberToMoneyWithCurrencySymbol(tagBalance),
        };
      }
    );
  }, [searchGuestRefundsReportResult]);

  const detailedData = searchGuestRefundsReportResult?.data?.content?.[0] || null;

  return (
    <ModuleWrapper>
      <HeaderA
        title={lang.guestRefundReport}
        description={lang.guestRefundReportDesc}
        button={{
          iconPrefix: <Icon className="mr-sm" name="download" paddingless fontSize={12} />,
          text: lang.exportXlsx,
          loading: searchGuestRefundsReportExportLoading,
          disabled: searchGuestRefundsReportExportLoading,
          onClick: () => {
            exportXlsx();
          },
        }}
        className="mb-md"
      />

      <GuestRefundReportFilter
        filterState={filterState}
        requestState={requestState}
        modifyFilter={modifyFilter}
        clearFilter={clearFilter}
        onClear={clearFilterCb}
        onApply={applyFilterCb}
      />

      {GuestRefundsReportsData?.length > 0 || loadingGuestRefundsReports ? (
        <>
          <div className="my-md">
            <Panel className="mt-md">
              <Title lg className="mb-md text-gray-700">
                {lang.guestRefundReport}
              </Title>
              {detailedData && (
                <div className="flex flex-col gap-2">
                  <div className="flex justify-between items-center">
                    <Text className="font-semibold w-1/3">Event ID: </Text>
                    <Text className="w-2/3">{detailedData["venueId"] || "--"}</Text>
                  </div>

                  <div className="flex justify-between items-center">
                    <Text className="font-semibold w-1/3">Event Name:</Text>
                    <Text className="w-2/3">{detailedData["eventName"] || "--"}</Text>
                  </div>

                  <div className="flex justify-between items-center">
                    <Text className="font-semibold w-1/3">Event Start Date:</Text>
                    <Text className="w-2/3">
                      {eventStartDateTime ? formatDate(eventStartDateTime, DateTime.W) : "--"}
                    </Text>
                  </div>
                  <div className="flex justify-between items-center">
                    <Text className="font-semibold w-1/3">Event End Date:</Text>
                    <Text className="w-2/3">
                      {eventEndDateTime ? formatDate(eventEndDateTime, DateTime.W) : "--"}
                    </Text>
                  </div>

                  <div className="flex justify-between items-center">
                    <Text className="font-semibold w-1/3">Refund Claim Start Date:</Text>
                    <Text className="w-2/3">
                      {refundClaimStartDateTime
                        ? formatDate(refundClaimStartDateTime, DateTime.W)
                        : "--"}
                    </Text>
                  </div>

                  <div className="flex justify-between items-center">
                    <Text className="font-semibold w-1/3">Refund Claim End Date:</Text>
                    <Text className="w-2/3">
                      {refundClaimEndDateTime
                        ? formatDate(refundClaimEndDateTime, DateTime.W)
                        : "--"}
                    </Text>
                  </div>
                </div>
              )}
            </Panel>
          </div>
          {searchGuestRefundsReportResult?.data?.totalElements ? (
            <text>
              {searchGuestRefundsReportResult.data?.totalElements || 0} {lang.searchResults}
            </text>
          ) : (
            ""
          )}
          {GuestRefundsReportsData &&
          <DataTable
            page={filterState.page}
            pageSize={filterState.pageSize}
            onChangePage={modifyFilters}
            fetchList={fetchGuestRefundsReports}
            total={searchGuestRefundsReportResult?.data?.totalElements || 0}
            loading={loadingGuestRefundsReports}
            columns={GuestRefundReportColumns}
            data={GuestRefundsReportsData}
            error={errorGuestRefundsReports}
            sort={filterState.sort}
            setSort={sortCb}
          />}
        </>
      ) : (
        noResult
      )}
    </ModuleWrapper>
  );
};

export default GuestRefundsReport;
