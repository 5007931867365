import { Field, RangePicker, ReportFilter, Text, TimePicker } from "components/commons";
import React, { useCallback, useEffect, useState } from "react";
import moment from "moment";
import lang from "translations";
import { modifyFromTimeRange, modifyToTimeRange } from "services";

const SalesReportFilter = ({ filterState, modifyFilter, clearFilter, applyFilter }) => {
  const [filterDisabled, setFilterDisabled] = useState(false);

  const applyFilterCb = useCallback(
    async (searchKey) => {
      await applyFilter(searchKey);
    },
    [applyFilter]
  );

  const clearFilterCb = useCallback(() => {
    clearFilter();
  }, [clearFilter]);

  useEffect(() => {
    const startDate = moment(filterState.dateRange[0].format("MM-DD-YYYY HH:mm:ss"));
    const endDate = moment(filterState.dateRange[1].format("MM-DD-YYYY HH:mm:ss"));
    if (startDate.isAfter(endDate)) {
      setFilterDisabled(true);
    } else {
      setFilterDisabled(false);
    }
  }, [filterState.dateRange]);

  return (
    <ReportFilter
      className="my-lg"
      onClear={clearFilterCb}
      onApply={applyFilterCb}
      disabled={filterDisabled}
      filterState={filterState}
      actionsSpan={8}
    >
      <Field className="col-span-4" filterLabel={lang.dateRange}>
        <RangePicker
          value={filterState.dateRange}
          name="dateRange"
          disabledDateRange={{ start: moment().subtract(3, "month"), end: moment() }}
          onChange={(name, value) => {
            if (value) {
              modifyFilter(name, {
                value,
              });
            }
          }}
        />
      </Field>
      <Field className="col-span-4" filterLabel={lang.timeRange}>
        <div className="flex items-center">
          <TimePicker
            error={filterDisabled}
            value={filterState.dateRange[0]}
            onChange={(val) => {
              modifyFromTimeRange(val, filterState, modifyFilter);
            }}
          />
          <Text className="mx-sm" color="text-black-light">
            {lang.to}
          </Text>
          <TimePicker
            error={filterDisabled}
            value={filterState.dateRange[1]}
            onChange={(val) => {
              modifyToTimeRange(val, filterState, modifyFilter);
            }}
          />
        </div>
      </Field>
    </ReportFilter>
  );
};

export default SalesReportFilter;
