const InvoicedSiigo = {
  Unbill: "UNBILL",
  SiigoAccepted: "SIIGO_ACCEPTED",
  SiigoError: "SIIGO_ERROR",
  DianAccepted: "DIAN_ACCEPTED",
  DianError: "DIAN_ERROR",
  PendingUnbill: "PENDING_UNBILL",
};

export default Object.freeze(InvoicedSiigo);
