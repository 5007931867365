import { transactionDateStore } from "hooks";

export const voucherMovementFilterState = (venue) => {
  const { venueId, startDate, endDate } = transactionDateStore(venue);
  return {
    venueId,
    page: 1,
    pageSize: 20,
    searchKey: "",
    // dateRange: [moment().startOf("month"), moment().endOf("day")],
    dateRange: [startDate, endDate],
    locations: [],
    staffs: [],
    voucherTypes: [],
    tapStatus: null,
    sort: { key: "dateCreated", value: "desc" },
  };
};
