import moment from "moment";
import { EMode } from "enums";


export const transactionDateStore = (venue) => {
  const { venueId, transactionStartDateTime, transactionEndDateTime, defaultMode } = venue;

  let startDate, endDate;

  if (defaultMode === EMode.Event && transactionStartDateTime && transactionEndDateTime) {
    startDate = moment(transactionStartDateTime);
    endDate = moment(transactionEndDateTime);
  } else {
    startDate = moment("00:00:00", "HH:mm:ss"); 
    endDate = moment("23:59:00", "HH:mm:ss"); 
  }

  return {
    startDate, endDate, venueId
  };
};

export default transactionDateStore;
