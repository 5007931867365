import { Title } from "components/commons";
import { ModuleWrapper } from "components/fragments";
import React, { useCallback, useContext, useMemo, useState } from "react";
import lang from "translations";
import { StaffContext, VenueContext } from "contexts";
import DashboardFilter from "./dashboard-filters";
import { useApi, useMount } from "hooks";
import { mixpanel, TrackEvent } from "mixpanel";
import {
  getGraphReportOfTotalSalesOfLocation,
  getGraphReportOfTotalSales,
  getTopLocationsBySales,
  getGraphOfTopUpSales,
  getGraphOfReturnCredits,
  getTopProductsBySales,
  getAverageOrderValue,
  getTotalTransactionsValue,
  getTopSpendingGuest,
  getTopCategoriesBySales,
  getTotalUnspentCredits,
  getTotalCreditsReceived,
  getTotalCreditsKept,
} from "apis/transaction.api";
import { searchProductsOutOfStock } from "apis/product.api";
import { getGraphOfActivatedGuest } from "apis/guest.api";
import { getOnboardingChecklist, closeOnboardingBanner } from "apis";
import DashboardCharts from "./dashboard-charts";
import {
  graphLineChartOfTotalSale,
  graphPieChartOfTopLocationSales,
  graphMultiLineChartOfTotalSale,
  topProduct,
  guestWithTotalSpend,
  topCategory,
  outOfStockProduct,
  graphTopUpTotal,
  graphLineChartOfTotalCheckIns,
  graphLineChartOfTotalReturns,
  checklistRequest,
} from "mappers";
import { toApiDateTimeFormat } from "services";
import DashboardOnboarding from "./dashboard-onboarding";
import { useFlags } from "launchdarkly-react-client-sdk";
import timeUnit from "enums/time-unit";

const Dashboard = () => {
  const { venue } = useContext(VenueContext);
  const { staff } = useContext(StaffContext);
  const [hasLocations, setHasLocations] = useState(false);
  const [params, setParams] = useState({});
  const [displayOnboarding, setDisplayOnboarding] = useState(true);
  const { onboardingFlag } = useFlags();

  const graphOfTotalSales = useApi({
    api: getGraphReportOfTotalSales,
    mapper: graphLineChartOfTotalSale,
    handleOwnError: true,
  });

  const graphOfTotalSalesOfLocation = useApi({
    api: getGraphReportOfTotalSalesOfLocation,
    mapper: graphMultiLineChartOfTotalSale,
    handleOwnError: true,
  });

  const topLocationsBySales = useApi({
    api: getTopLocationsBySales,
    mapper: graphPieChartOfTopLocationSales,
    isArray: true,
    handleOwnError: true,
  });

  const graphOfTopUpSales = useApi({
    api: getGraphOfTopUpSales,
    mapper: graphTopUpTotal,
    handleOwnError: true,
  });

  const graphOfReturnCredits = useApi({
    api: getGraphOfReturnCredits,
    mapper: graphLineChartOfTotalReturns,
    handleOwnError: true,
  });

  const graphOfActivatedGuest = useApi({
    api: getGraphOfActivatedGuest,
    mapper: graphLineChartOfTotalCheckIns,
    handleOwnError: true,
  });

  const topProductsBySales = useApi({
    api: getTopProductsBySales,
    params: {
      limit: 5,
    },
    mapper: topProduct,
    isArray: true,
    handleOwnError: true,
  });

  const averageOrderValue = useApi({
    api: getAverageOrderValue,
    handleOwnError: true,
  });

  const totalTransactionsValue = useApi({
    api: getTotalTransactionsValue,
    handleOwnError: true,
  });

  const totalUnspentCredits = useApi({
    api: getTotalUnspentCredits,
    handleOwnError: true,
  });

  const topSpendingGuest = useApi({
    api: getTopSpendingGuest,
    params: {
      limit: 5,
    },
    mapper: guestWithTotalSpend,
    isArray: true,
    handleOwnError: true,
  });

  const outOfStockProducts = useApi({
    api: searchProductsOutOfStock,
    mapper: outOfStockProduct,
    isArray: true,
    handleOwnError: true,
  });

  const topCategoriesBySales = useApi({
    api: getTopCategoriesBySales,
    params: {
      limit: 5,
    },
    mapper: topCategory,
    isArray: true,
    handleOwnError: true,
  });

  const {
    request: fetchOnboardingChecklist,
    mappedData: onboardingChecklist,
    loading: onboardingCheklistLoading,
  } = useApi({
    api: getOnboardingChecklist,
    params: {
      venueId: venue.venueId,
    },
    mapper: checklistRequest,
    handleOwnError: true,
  });

  const { request: closeBannerRequest } = useApi({
    api: closeOnboardingBanner,
    params: {
      venueId: venue.venueId,
    },
    handleOwnError: true,
  });

  const totalCreditsReceived = useApi({
    api: getTotalCreditsReceived,
    handleOwnError: true,
  });

  const totalCreditsKept = useApi({
    api: getTotalCreditsKept,
    handleOwnError: true,
  });

  const fetchCharts = useCallback(
    async (filterState) => {
      const { locationIds, dateRange, interval } = filterState;
      let splitType = timeUnit.Hour;

      if (interval === timeUnit.Day || interval === timeUnit.Week || interval === timeUnit.WeekS) {
        splitType = timeUnit.Day;
      } else if (interval === timeUnit.Month || interval === timeUnit.Quarter) {
        splitType = timeUnit.Month;
      }

      const params = {
        ...filterState,
        startDateTime: toApiDateTimeFormat(dateRange[0]),
        endDateTime: toApiDateTimeFormat(dateRange[1], true),
        splitType,
      };
      setParams(params);
      const hasLocationValue = Boolean(locationIds.length);
      setHasLocations(hasLocationValue);
      const charts = [
        graphOfTopUpSales,
        graphOfReturnCredits,
        graphOfActivatedGuest,
        topProductsBySales,
        averageOrderValue,
        totalTransactionsValue,
        topSpendingGuest,
        topCategoriesBySales,
        // outOfStockProducts,
        totalUnspentCredits,
        totalCreditsReceived,
        totalCreditsKept,
      ];

      if (hasLocationValue) {
        charts.push(graphOfTotalSalesOfLocation);
      } else {
        charts.push(graphOfTotalSales);
        charts.push(topLocationsBySales);
      }

      try {
        await Promise.all(charts.map((request) => request.request(params)));
      } catch (error) {
        console.error("Error occurred during API requests:", error);
      }
    },
    [
      graphOfReturnCredits,
      graphOfTopUpSales,
      graphOfTotalSales,
      graphOfTotalSalesOfLocation,
      topLocationsBySales,
      graphOfActivatedGuest,
      topProductsBySales,
      averageOrderValue,
      totalTransactionsValue,
      topSpendingGuest,
      topCategoriesBySales,
      // outOfStockProducts,
      totalUnspentCredits,
      totalCreditsReceived,
      totalCreditsKept,
    ]
  );

  useMount(() => {
    mixpanel.track(TrackEvent.VisitedPage, {
      Page: lang.dashboard,
    });
    fetchOnboardingChecklist();
  });

  const graphs = useMemo(() => {
    const charts = {
      graphOfReturnCredits,
      graphOfTopUpSales,
      graphOfTotalSales,
      graphOfTotalSalesOfLocation,
      topLocationsBySales,
      graphOfActivatedGuest,
      topProductsBySales,
      averageOrderValue,
      totalTransactionsValue,
      topSpendingGuest,
      topCategoriesBySales,
      outOfStockProducts,
      totalUnspentCredits,
      totalCreditsReceived,
      totalCreditsKept,
    };
    Object.keys(charts).forEach((chart) => {
      charts[chart].retry = () => {
        fetchCharts(params);
      };
    });
    return charts;
  }, [
    graphOfReturnCredits,
    graphOfTopUpSales,
    graphOfTotalSales,
    graphOfTotalSalesOfLocation,
    topLocationsBySales,
    graphOfActivatedGuest,
    topProductsBySales,
    averageOrderValue,
    totalTransactionsValue,
    topSpendingGuest,
    topCategoriesBySales,
    outOfStockProducts,
    totalUnspentCredits,
    totalCreditsReceived,
    totalCreditsKept,
    params,
    fetchCharts,
  ]);

  return (
    <ModuleWrapper>
      {onboardingFlag && !venue.bannerClosed && displayOnboarding && (
        <DashboardOnboarding
          setDisplay={setDisplayOnboarding}
          onboardingStatus={onboardingChecklist}
          closeBannerRequest={closeBannerRequest}
          loading={onboardingCheklistLoading}
        />
      )}
      <Title className="mt-md font-semibold sm:text-xl">
        {lang.populate(lang.hiHeresWhatsHappening, [staff.name, venue.venueName])}
      </Title>
      <DashboardFilter venue={venue} venueId={venue.venueId} fetchCharts={fetchCharts} />
      <DashboardCharts {...graphs} hasLocations={hasLocations} />
    </ModuleWrapper>
  );
};

export default Dashboard;
