import { transactionDateStore } from "hooks";

export const duplicateTicketReportFilterState = (venue) => {
  const { venueId, startDate, endDate } = transactionDateStore(venue);
  return {
    venueId,
    page: 1,
    pageSize: 20,
    dateRange: [startDate, endDate]
  };
};
