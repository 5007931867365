import { transactionDateStore } from "hooks";

export const balanceMovementListFilterState = (venue) => {
  const { venueId, startDate, endDate } = transactionDateStore(venue);
  return {
    venueId,
    searchKey: "",
    venueList: [],
    pageable: true,
    page: 1,
    pageSize: 20,
    dateRange: [startDate, endDate],
    startDateTime: null,
    endDateTime: null,
    statusList: [],
  };
};
