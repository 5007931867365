import { transactionDateStore } from "hooks";

export const stocktakeFilter = (venue) => {
  const { venueId, startDate, endDate } = transactionDateStore(venue);
  return {
    venueId,
    searchKey: "",
    dateRange: [startDate, endDate],
    page: 1,
    pageSize: 20,
    pageable: true,
    status: [],
    locations: [],
    sort: { key: "updatedDate", value: "desc" },
  };
};
