import { transactionDateStore } from "hooks";

export const shiftListFilterState = (venue, eod) => {
  const { venueId, startDate, endDate } = transactionDateStore(venue);
  return {
    venueId,
    page: 1,
    pageSize: 20,
    searchKey: "",
    dateRange: [startDate, endDate],
    locations: [],
    staffs: [],
    devices: [],
    sort: { key: "end", value: "desc" },
    eod: eod,
  };
};
