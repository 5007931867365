import { searchTipReport, searchTipReportExport } from "apis";
import { DataTable, Icon, Text, ButtonLink } from "components/commons";
import { VenueContext } from "contexts";
import { useApi, useFilter, useMount } from "hooks";
import { tipReportResponseMapper, tipReportListRequest } from "mappers";
import React, { useCallback, useContext, useMemo } from "react";
import { Path } from "paths";
import { mapObject } from "services";
import { tipReportFilterState } from "./filters";
import TipReportFilter from "./tip-report-filter";
import { PaymentMethod } from "enums";
import lang from "translations";
import { HeaderA } from "components/headers";
import { ModuleWrapper } from "components/fragments";
import { mixpanel, TrackEvent } from "mixpanel";

const TipReport = () => {
  const { venue } = useContext(VenueContext);

  const {
    request: searchTipReportRequest,
    loading: loadingTipReports,
    result: searchTipReportResult = { data: [], metadata: { total: 0 } },
    mappedData: tipReportsData,
    error: errorTipReports,
  } = useApi({
    api: searchTipReport,
    isArray: true,
    mapper: tipReportResponseMapper,
  });

  const { request: searchTipReportExportRequest, loading: searchTipReportExportLoading } =
    useApi({
      api: searchTipReportExport,
    });

  const { modifyFilter, modifyFilters, filterState, requestState, clearFilter } = useFilter(
    tipReportFilterState(venue)
  );

  useMount(() => {
    fetchTipReports(filterState, requestState);

    mixpanel.track(TrackEvent.VisitedPage, {
      Page: lang.categoryReport,
    });
  });

  const fetchTipReports = useCallback(
    (filterState, requestState) => {
      searchTipReportRequest(
        mapObject({ ...requestState }, tipReportListRequest)
      );
    },
    [searchTipReportRequest]
  );

  const renderDateAndTime = useCallback((dateAndTime) => {
    console.log("renderDateAndTime ", dateAndTime);
    return (
      <div>
        <Text>{dateAndTime?.date}</Text>
        {dateAndTime?.time && <Text color="text-gray">{dateAndTime.time}</Text>}
      </div>
    );
  }, []);

  const renderTransaction = useCallback((transactionId) => {
    return (
      <a href={"/transaction/" + transactionId} target="_blank" rel="noreferrer">
        <Text color="text-pelorous" className="whitespace-nowrap">{`#${transactionId}`}</Text>
      </a>
    );
  }, []);

  const renderGuest = useCallback(
    (name, tagUid, guestId, paymentMethod) => {
      const isPaidByCashOrCard = [
        PaymentMethod.Cash,
        PaymentMethod.CreditDebit,
        PaymentMethod.Credit,
        PaymentMethod.Others,
      ].includes(paymentMethod);

      if (isPaidByCashOrCard) {
        return (
          <div>
            <Text strong>-</Text>
          </div>
        );
      }

      let guestTagDisplay = "";

      guestTagDisplay = (
        <div className="flex items-center">
          <Text className="mr-sm group-hover:text-black-light" tagUid>
            {tagUid}
          </Text>
        </div>
      );

      return (
        <ButtonLink
          className="grid grid-cols-1 cursor-pointer group"
          path={Path.GUEST_DETAILS_ID(guestId)}
        >
          {name ? (
            <Text color="text-left text-pelorous group-hover:text-pelorous-dark">{name}</Text>
          ) : (
            <Text color="text-left text-pelorous group-hover:text-pelorous-dark">
              {[lang.guest, " ", guestId]}
            </Text>
          )}
          <div>{guestTagDisplay}</div>
        </ButtonLink>
      );
    },
    []
  );

  const prepareReports = useCallback(() => {
    return tipReportsData.map((reportRow) => {
      const {
        transactionId,
        guestId,
        name,
        userTagUid,
        transactionDate,
        locationName,
        type,
        staffName,
        tipAmount,
        paymentMethod,
      } = reportRow;

      return {
        transactionId: renderTransaction(transactionId),
        name: renderGuest(name, userTagUid, guestId, paymentMethod),
        transactionDate: renderDateAndTime(transactionDate),
        locationName,
        type,
        staffName,
        tipAmount: tipAmount,
      };
    });
  }, [tipReportsData, renderDateAndTime, renderTransaction, renderGuest]);

  const tipReports = useMemo(() => {
    return prepareReports();
  }, [prepareReports]);

  const clearFilterCb = useCallback(
    (filterState, requestState) => {
      fetchTipReports(filterState, requestState);
    },
    [fetchTipReports]
  );

  const applyFilterCb = useCallback(
    (searchKey) => {
      const { filterState, requestState } = modifyFilters({
        page: 1,
        searchKey,
      });
      fetchTipReports(filterState, requestState);
    },
    [fetchTipReports, modifyFilters]
  );

  const sortCb = useCallback(
    ({ value, key }) => {
      const { filterState, requestState } = modifyFilters({ sort: { key, value } });
      fetchTipReports(filterState, requestState);
    },
    [fetchTipReports, modifyFilters]
  );

  const exportXlsx = useCallback(() => {
    searchTipReportExportRequest(
      mapObject({ ...requestState }, tipReportListRequest)
    );
  }, [searchTipReportExportRequest, requestState]);

  const prepareColumns = useCallback(() => {
    return [
      {
        key: "transactionId",
        text: lang.transaction,
      },
      {
        key: "name",
        text: lang.guest,
      },
      {
        key: "transactionDate",
        text: lang.date,
        sort: true,
      },
      {
        key: "locationName",
        text: lang.location,
        sort: true,
      },
      {
        key: "type",
        text: lang.type,
      },
      {
        key: "staffName",
        text: lang.staffName,
      },
      {
        key: "tipAmount",
        text: lang.tips,
      },
    ];
  }, []);

  const columns = useMemo(() => {
    return prepareColumns();
  }, [prepareColumns]);

  const noResult = useMemo(() => {
    return (
      <div className="pt-lg">
        <p className="text-xl text-gray-600">{lang.noReportsFound}</p>
        <p className="text-md text-gray-400">{lang.weDidNotFoundReports}</p>
      </div>
    );
  }, []);

  return (
    <ModuleWrapper>
      <HeaderA
        title={lang.tipReport}
        button={{
          iconPrefix: <Icon className="mr-sm" name="download" paddingless fontSize={12} />,
          text: lang.exportXlsx,
          loading: searchTipReportExportLoading,
          disabled: searchTipReportExportLoading,
          onClick: () => {
            exportXlsx();
          },
        }}
        className="mb-md"
      />

      <TipReportFilter
        filterState={filterState}
        requestState={requestState}
        modifyFilter={modifyFilter}
        clearFilter={clearFilter}
        onClear={clearFilterCb}
        onApply={applyFilterCb}
      />

      {tipReports.length > 0 || loadingTipReports ? (
        <DataTable
          page={filterState.page}
          pageSize={filterState.pageSize}
          onChangePage={modifyFilters}
          fetchList={fetchTipReports}
          total={searchTipReportResult.metadata.total}
          loading={loadingTipReports}
          columns={columns}
          data={tipReports}
          error={errorTipReports}
          sort={filterState.sort}
          setSort={sortCb}
        />
      ) : (
        noResult
      )}
    </ModuleWrapper>
  );
};

export default TipReport;
