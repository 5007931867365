import { searchRefundClaimsReport, searchRefundClaimsReportExport } from "apis";
import { DataTable, Icon } from "components/commons";
import { VenueContext } from "contexts";
import { useApi, useFilter, useMount } from "hooks";
import { refundClaimssReportRequest } from "mappers";
import React, { useCallback, useContext, useMemo } from "react";
import { mapObject } from "services";
import { HeaderA } from "components/headers";
import { refundClaimReportFilterState } from "./filters";
import GuestRefundReportFilter from "./refund-claims-report-filter";
import { RefundClaimsReportColumns } from "./columns";
import lang from "translations";
import { ModuleWrapper } from "components/fragments";
import { mixpanel, TrackEvent } from "mixpanel";
import { formatNumberToMoneyWithCurrencySymbol } from "services/money.service";
import { formatDate, formatTagUIds } from "services";
import { DateTime } from "enums";

const RefundClaimsReport = () => {
  const { venue } = useContext(VenueContext);
  const {
    request: searchRefundClaimsReportRequest,
    loading: loadingRefundClaimsReports,
    result: searchRefundClaimsReportResult = { data: [], metadata: { total: 0 } },
    error: errorRefundClaimsReports,
  } = useApi({
    api: searchRefundClaimsReport,
    isArray: true
  });

  const {
    request: searchRefundClaimsReportExportRequest,
    loading: searchRefundClaimsReportExportLoading,
  } = useApi({
    api: searchRefundClaimsReportExport,
    params: {
      reportType : "REFUND_REPORT_CLAIMED",
      venueId: venue.venueId,
    },
  });

  const { modifyFilter, modifyFilters, filterState, requestState, clearFilter } = useFilter(
    refundClaimReportFilterState(venue)
  );

  useMount(() => {
    fetchRefundClaimsReports(filterState, requestState);

    mixpanel.track(TrackEvent.VisitedPage, {
      Page: lang.RefundClaimsReport,
    });
  });

  const fetchRefundClaimsReports = useCallback(
    (filterState, requestState) => {
      const requestData = {
        ...requestState,
        searchKey: requestState.searchKey ?? '',
        status: requestState.status ?? '',
      };
      searchRefundClaimsReportRequest(mapObject(requestData, refundClaimssReportRequest));
    },
    [searchRefundClaimsReportRequest]
  );

  const clearFilterCb = useCallback(
    (filterState, requestState) => {
      fetchRefundClaimsReports(filterState, requestState);
    },
    [fetchRefundClaimsReports]
  );

  const applyFilterCb = useCallback(
    (searchKey) => {
      const { filterState, requestState } = modifyFilters({
        page: 1,
        searchKey,
      });
      fetchRefundClaimsReports(filterState, requestState);
    },
    [fetchRefundClaimsReports, modifyFilters]
  );

  const sortCb = useCallback(
    ({ value, key }) => {
      const { filterState, requestState } = modifyFilters({ sort: { key, value } });
      fetchRefundClaimsReports(filterState, requestState);
    },
    [fetchRefundClaimsReports, modifyFilters]
  );

  const exportXlsx = useCallback(() => {
    searchRefundClaimsReportExportRequest(
      mapObject({ ...requestState }, refundClaimssReportRequest)
    );
  }, [searchRefundClaimsReportExportRequest, requestState]);

  const noResult = useMemo(() => {
    return (
      <div className="pt-lg">
        <p className="text-xl text-gray-600">{lang.noReportsFound}</p>
        <p className="text-md text-gray-400">{lang.weDidNotFoundReports}</p>
      </div>
    );
  }, []);

  const RefundClaimsReportsData = useMemo(() => {
    return searchRefundClaimsReportResult.data?.map(
      ({
        firstName,
        lastName,
        email,
        phoneNumber,
        tagUid,
        tagBalance,
        refundedFeeAmount,
        refundedTransferredAmount,
        refundedDate,
        status,
      }) => {
        return {
          firstName,
          lastName,
          email,
          phoneNumber,
          tagUid: formatTagUIds(tagUid),
          tagBalance: formatNumberToMoneyWithCurrencySymbol(tagBalance),
          refundedFeeAmount: formatNumberToMoneyWithCurrencySymbol(refundedFeeAmount),
          requestAmount: formatNumberToMoneyWithCurrencySymbol(refundedTransferredAmount),
          refundedDate : formatDate(refundedDate, DateTime.A),
          status : status === "REFUNDED" ? lang.completed : '',
        };
      }
    );
  }, [searchRefundClaimsReportResult]);

  return (
    <ModuleWrapper>
      <HeaderA
        title={lang.refundClaimReport}
        description={lang.refundClaimReportDesc}
        button={{
          iconPrefix: <Icon className="mr-sm" name="download" paddingless fontSize={12} />,
          text: lang.exportXlsx,
          loading: searchRefundClaimsReportExportLoading,
          disabled: searchRefundClaimsReportExportLoading,
          onClick: () => {
            exportXlsx();
          },
        }}
        className="mb-md"
      />

      <GuestRefundReportFilter
        filterState={filterState}
        requestState={requestState}
        modifyFilter={modifyFilter}
        clearFilter={clearFilter}
        onClear={clearFilterCb}
        onApply={applyFilterCb}
      />

      {searchRefundClaimsReportResult.data.length > 0 || loadingRefundClaimsReports ? (
        <>
          {searchRefundClaimsReportResult?.data?.total ? (
            <text>
              {searchRefundClaimsReportResult.data?.total || 0} {lang.searchResults}
            </text>
          ) : (
            ""
          )}
          <DataTable
            page={filterState.page}
            pageSize={filterState.pageSize}
            onChangePage={modifyFilters}
            fetchList={fetchRefundClaimsReports}
            total={searchRefundClaimsReportResult?.metadata?.total || 0}
            loading={loadingRefundClaimsReports}
            columns={RefundClaimsReportColumns}
            data={RefundClaimsReportsData}
            error={errorRefundClaimsReports}
            sort={filterState.sort}
            setSort={sortCb}
          />
        </>
      ) : (
        noResult
      )}
    </ModuleWrapper>
  );
};

export default RefundClaimsReport;
