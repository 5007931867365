import { searchVoucherMovement } from "apis";
import { ButtonLink, DataTable, Pill, Text } from "components/commons";
import { VenueContext } from "contexts";
import { useApi, useFilter, useMount, useRouter } from "hooks";
import { PillType, TapStatus, VoucherType } from "enums";
import { voucherMovementListFilterRequest, voucherMovementListResponse } from "mappers";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { mapObject, pluralize, prettifyVoucherType } from "services";
import columns from "./columns";
import { voucherMovementFilterState } from "./filters";
import VoucherMovementFilter from "./voucher-movement-filter";
import lang from "translations";
import { ArtOctopus } from "images";
import { Path } from "paths";
import { mixpanel, TrackEvent } from "mixpanel";

const VoucherMovement = () => {
  const { venue } = useContext(VenueContext);
  const history = useRouter();

  const {
    request: searchMovementRequest,
    loading: loadingMovement,
    result: searchMovementResult = { data: [], metadata: { total: 0 } },
    error,
    mappedData,
  } = useApi({
    api: searchVoucherMovement,
    isArray: true,
    mapper: voucherMovementListResponse,
    handleOwnError: true,
  });

  const { modifyFilter, modifyFilters, filterState, requestState, clearFilter, isFilterDirty } =
    useFilter(voucherMovementFilterState(venue));

  const [hasAppliedFilter, setHasAppliedFilter] = useState(isFilterDirty);

  useMount(() => {
    fetchMovements(requestState);
    mixpanel.track(TrackEvent.VisitedPage, {
      Page: lang.voucherMovementPage,
    });
  });

  const fetchMovements = useCallback(
    (requestState) => {
      searchMovementRequest(mapObject(requestState, voucherMovementListFilterRequest));
    },
    [searchMovementRequest]
  );

  const renderTransactionId = useCallback(
    (transactionId) => (
      <ButtonLink
        onClick={() => {
          window.open(Path.TRANSACTION_ID(transactionId));
        }}
      >
        #{transactionId}
      </ButtonLink>
    ),
    []
  );

  const renderDateCreated = useCallback(
    (dateCreated) => (
      <div>
        <Text>{dateCreated?.date}</Text>
        {dateCreated?.time && <Text color="text-gray">{dateCreated.time}</Text>}
      </div>
    ),
    []
  );

  const renderVoucherName = useCallback(
    (voucherName, voucherId) => (
      <div className="cursor-pointer" onClick={() => history.push(Path.VOUCHER_ID(voucherId))}>
        <Text className="hover:text-gray">{voucherName}</Text>
      </div>
    ),
    [history]
  );

  const renderGuestName = useCallback(
    (guestName, userTagUid, guestId) => (
      <div
        className="cursor-pointer group"
        onClick={() => {
          history.push(Path.GUEST);
        }}
      >
        <Text className="group-hover:text-gray">{guestName}</Text>
        <Text className="group-hover:text-gray" tagUid>
          {userTagUid}
        </Text>
      </div>
    ),
    [history]
  );

  const renderLocationName = useCallback(
    (locationName, locationId) => (
      <div className="cursor-pointer" onClick={() => history.push(Path.LOCATION_ID(locationId))}>
        <Text className="hover:text-gray">{locationName}</Text>
      </div>
    ),
    [history]
  );

  const renderStaffName = useCallback(
    (staffName, staffId) => (
      <div className="cursor-pointer" onClick={() => history.push(Path.STAFF_ID(staffId))}>
        <Text className="hover:text-gray">{staffName}</Text>
      </div>
    ),
    [history]
  );

  const renderVoucherType = useCallback((type, tapStatus) => {
    if (tapStatus === TapStatus.Failed) {
      return (
        <Pill size="text-xs whitespace-nowrap" type={PillType.Red}>
          {`${lang.failed} ${prettifyVoucherType(type)}`}
        </Pill>
      );
    }
    if (type === VoucherType.Issue) {
      return (
        <Pill size="text-xs whitespace-nowrap" type={PillType.Green}>
          {prettifyVoucherType(type)}
        </Pill>
      );
    }
    if (type === VoucherType.Redeem) {
      return (
        <Pill size="text-xs whitespace-nowrap" type={PillType.Blue}>
          {prettifyVoucherType(type)}
        </Pill>
      );
    }
    if (type === VoucherType.Remove) {
      return (
        <Pill size="text-xs whitespace-nowrap" type={PillType.Gray}>
          {prettifyVoucherType(type)}
        </Pill>
      );
    }
    return null;
  }, []);

  const prepareMovementList = useCallback(() => {
    return mappedData.map((movement) => {
      const {
        transactionId,
        dateCreated,
        name,
        quantity,
        guestName,
        userTagUid,
        locationName,
        staffName,
        voucherType,
        tapStatus,
        locationId,
        voucherId,
        staffProfileId,
        guestId,
      } = movement;

      return {
        transactionId: renderTransactionId(transactionId),
        dateCreated: renderDateCreated(dateCreated),
        voucher: renderVoucherName(name, voucherId),
        quantity,
        guest: renderGuestName(guestName, userTagUid, guestId),
        locationName: renderLocationName(locationName, locationId),
        staffName: renderStaffName(staffName, staffProfileId),
        voucherType: renderVoucherType(voucherType, tapStatus),
      };
    });
  }, [
    mappedData,
    renderDateCreated,
    renderGuestName,
    renderTransactionId,
    renderVoucherType,
    renderLocationName,
    renderStaffName,
    renderVoucherName,
  ]);

  const movements = useMemo(() => {
    return prepareMovementList();
  }, [prepareMovementList]);

  const applyFilterCb = useCallback(
    (searchKey) => {
      const { requestState } = modifyFilters({ page: 1, searchKey });
      fetchMovements(requestState);

      setHasAppliedFilter(true);
    },
    [fetchMovements, modifyFilters]
  );

  const clearFilterCb = useCallback(async () => {
    const { requestState } = await clearFilter();
    fetchMovements(requestState);

    setHasAppliedFilter(false);
    return requestState;
  }, [clearFilter, fetchMovements]);

  const movementResultContent = useMemo(() => {
    if (hasAppliedFilter) {
      return `${searchMovementResult.metadata.total} ${pluralize(
        searchMovementResult.metadata.total,
        lang.searchResult,
        lang.searchResults
      )}`;
    }
    return null;
  }, [searchMovementResult.metadata.total, hasAppliedFilter]);

  const sortCb = useCallback(
    ({ value, key }) => {
      const { requestState } = modifyFilters({ sort: { key, value } });
      fetchMovements(requestState);
    },
    [fetchMovements, modifyFilters]
  );

  return (
    <div>
      <VoucherMovementFilter
        filterState={filterState}
        requestState={requestState}
        modifyFilter={modifyFilter}
        modifyFilters={modifyFilters}
        applyFilter={applyFilterCb}
        clearFilter={clearFilterCb}
        fetchMovements={fetchMovements}
        venueId={venue.venueId}
      />
      <DataTable
        resultContent={movementResultContent}
        page={filterState.page}
        pageSize={filterState.pageSize}
        onChangePage={modifyFilters}
        fetchList={fetchMovements}
        total={searchMovementResult.metadata.total}
        loading={loadingMovement}
        columns={columns}
        data={movements}
        error={error}
        sort={filterState.sort}
        setSort={sortCb}
        minWidth="1000px"
        hasAppliedFilter={isFilterDirty && hasAppliedFilter}
        renderEmpty={{
          image: ArtOctopus,
          title: lang.noVoucherMovement,
        }}
      />
    </div>
  );
};

export default VoucherMovement;
