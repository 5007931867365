import { timeAgo } from "services";
import { dateTimeRequest, dateToTimeAgo, filterRequest } from "./common.mapper";

export const deviceListResponse = {
  id: { key: "deviceId" },
  name: { key: "deviceName" },
  imei: { key: "deviceImei" },
  appVersion: { key: "appVersion" },
  appDate: {
    transform: ({ src: { appDate } }) => {
      return appDate ? timeAgo(appDate) : "";
    },
  },
  serialNumber: { key: "deviceSerialNumber" },
  lastSync: dateToTimeAgo("transactionLastSynced"),
  deviceLocation: {
    transform: ({ src: { longitude, latitude } }) => {
      if (longitude !== undefined && latitude !== undefined) {
        return `${longitude} : ${latitude}`;
      }
      return ''; 
    },
  },  
  venueLocation : { key: "venueLocation" },
};

export const deviceListFilterRequest = {
  ...filterRequest,
  ...dateTimeRequest,
  orders: {
    transform: ({ src }) => {
      return { [`d.transactionLastSynced`]: src.sort?.value || "desc" };
    },
  },
  searchKey: { key: "searchKey" },
};

export const deviceWithTransactionFilterRequest = {
  venueId: { key: "venueId" },
  ...dateTimeRequest,
};

export const deviceWithTransactionFilterResponse = {
  id: { key: "deviceId" },
  name: { key: "deviceName" },
};

export const device = {
  id: { key: "deviceId" },
  name: {
    key: "deviceName",
  },
  deleted: {
    key: "deleted",
  },
  imei: {
    key: "deviceImei",
  },
  macAddress: {
    key: "deviceMacAddress",
  },
  serialNumber: {
    key: "deviceSerialNumber",
  },
};

export const deviceUpdateRequest = {
  deviceId: { key: "id" },
  deviceName: {
    key: "name",
  },
};
